<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} sabbaticals<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'researcher.administration.sabbaticals.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'Sabbatical')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="item" />

            <div class="card">
              <div
                v-b-toggle="`collapseContent`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseContent`"
                visible
              >
                <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.purpose'] }}</label>
                          <quill-editor v-model="item.purpose" />
                        </div>
                      </div>
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                    <h3 class="cart-title">
                      <a data-action="collapse">{{ labels['content.dates'] }}</a>
                    </h3>
                    <div class="row">
                      <div class="col-2">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.begin_date'] }}</label>
                          <date-picker
                            v-model="item.begin_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.end_date'] }}</label>
                          <date-picker
                            v-model="item.end_date"
                            format="D MMM Y"
                            class="w-100"
                            value-type="format"
                          />
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.status'] }}</label>
                          <v-select
                            v-if="loggedUser.roles.includes('super-admin')"
                            v-model="item.status"
                            label="status"
                            :options="statuses"
                            :get-option-key="option => option.id"
                          />
                          <div
                            v-else-if="item.status"
                            v-html="item.status.status_table"
                          />
                          <div
                            v-else
                          >
                            <span class="badge bg-light-warning">{{ labels['content.authorization_required'] }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.institution'] }}</label>
                        <input
                          v-model="item.institution_manual"
                          type="text"
                          class="form-control"
                        >
                        <!-- <v-select
                          v-model="item.institutions"
                          label="name"
                          :clearable="false"
                          multiple
                          :options="hosts"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'institutions/filter')"
                        /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <div
              v-if="item.icrea_remarks || loggedUser.roles.includes('super-admin')"
              class="card"
            >
              <div
                v-b-toggle="`collapseIcreaRemark`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">ICREA's remarks</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseIcreaRemark`"
              >
                <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labelsIcreaRemarks['content'] }}</label>
                          <quill-editor
                            v-if="loggedUser.roles.includes('super-admin')"
                            v-model="item.icrea_remarks"
                          />
                          <div
                            v-else
                            v-html="item.icrea_remarks"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <Trip
              :item="item"
            />

            <AddFile
              :id="6"
              :title="'Support documentation'"
              :type="4"
              :files="item.files_"
            />

            <InternalNotes
              :notes="item.notes"
              @saveNewNote="saveNewNote"
            />

            <AttachedForm
              :type="'Sabbatical'"
              :model-id="sabbaticalsId"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import AddFile from '../../../admin/senior-call/components/AddFile.vue'
import SearchResearcher from '../../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import Trip from '../../activities/components/Trip.vue'
import AttachedForm from '../../../forms/partials/AttachedForm.vue'

export default {
  components: {
    AddFile,
    SearchResearcher,
    InternalNotes,
    vSelect,
    DatePicker,
    Trip,
    BCollapse,
    AttachedForm,
  },
  data() {
    return {
      sabbaticalsId: this.$route.params.id,
      sending: false,
      errors: '',
      labelsIcreaRemarks: [],
    }
  },
  computed: {
    ...mapGetters({
      item: 'sabbaticals/item',
      statuses: 'status/statuses',
      authorizations: 'sabbaticalsAuth/items',
      hosts: 'institutions/institutions',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
      user: 'users/user',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'administration.sabbaticals')
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.icrea_remarks`)
      this.labelsIcreaRemarks = data.data
    } catch (e) {
      console.error(e)
    }

    if (this.sabbaticalsId) {
      await this.$store.dispatch('sabbaticals/fetchId', this.sabbaticalsId)
    } else {
      await this.$store.dispatch('sabbaticals/cleanType')
      Vue.set(this.item, 'user', this.user)
    }

    this.$store.dispatch('status/filterStatus', 'Sabbaticals')
  },
  methods: {
    async save() {
      this.sending = true
      this.item.attached_form = this.attached

      const checkDates = await this.checkDates()

      if (!checkDates) {
        this.sending = false
        return
      }

      try {
        if (this.sabbaticalsId) {
          await this.$store.dispatch('sabbaticals/update', { id: this.sabbaticalsId, data: this.item })
        } else {
          await this.$store.dispatch('sabbaticals/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The sabbatical has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.administration.sabbaticals.index' })
        })
      } else {
        Vue.swal('Error on saving the documentation.', 'Please, fill up all the required fields', 'error')
      }

      this.sending = false
    },
    async checkDates() {
      const resp = await this.$store.dispatch('sabbaticals/checkDates', { ...this.item })

      let check = true

      if (resp.status === 'warning') {
        await Vue.swal({
          title: 'Are you sure?<br>',
          html: resp.message.join('<br>'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (!result.isConfirmed) {
            check = false
          }
        })
      } else if (resp.status === 'error') {
        Vue.swal(resp.message, '', 'error')
        check = false
      }

      return check
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
  },
}
</script>
